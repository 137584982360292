import * as React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

const Footer = () => (
  <footer className="bg-light border-top">
    <Container className="text-secondary my-3">
      <div className="px-4 mb-3">
        <div className="text-start">
          <div className="row small">
            <Link to="/privacy-policy" className="col-lg-3 col-6 py-1 px-1 footer-text">プライバシーポリシー</Link>
            <Link to="/disclaimer" className="col-lg-3 col-6 py-1 px-1 footer-text">免責事項</Link>
            <Link to="/copyright" className="col-lg-3 col-6 py-1 px-1 footer-text">著作権について</Link>
            <Link to="/about-links" className="col-lg-3 col-6 py-1 px-1 footer-text">リンクについて</Link>
          </div>
        </div>
      </div>
      <div className="text-center">
        <span>Copyright (C) 2021- footlog開発者のブログ</span>
        <span className="ms-2">by <a href="https://footlog.net/">footlog.net</a></span>
      </div>
    </Container>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer;
