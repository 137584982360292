import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container, Navbar, Nav } from 'react-bootstrap';
import logo from '../images/logo.png';
import { faLink, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = ({ siteTitle }) => (
  <header>
    <Navbar bg="light" variant="light" className="border-bottom">
      <Container>
        <Navbar.Brand>
          <Link to="/" className="text-decoration-none">
            <img
              src={logo}
              width="35"
              height="35"
              alt="logo"
            />
            <h1 className="h3 d-inline ms-1" style={{color: "#424242"}}>{siteTitle}</h1>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse className="justify-content-end" id="navbarResponsive">
          <Nav as="ul" className="ml-auto">
            <Nav.Item as="li" className="mx-2">
              <a href="https://www.footlog.net" className="nav-link btn-secondary rounded-pill text-white">
                <span style={{marginLeft:"2px", marginRight:"2px"}}>
                  <FontAwesomeIcon className="fa-fw text-white" icon={faLink}></FontAwesomeIcon>
                </span>
                <span className="d-none d-md-inline">footlog</span>
              </a>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Link to="/about" className="nav-link btn btn-secondary rounded-pill text-white">
                <span style={{marginLeft:"2px", marginRight:"2px"}}>
                  <FontAwesomeIcon className="fa-fw text-white" icon={faUser}></FontAwesomeIcon>
                </span>
                <span className="d-none d-md-inline">about</span>
              </Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Link to="/contact" className="nav-link btn btn-secondary rounded-pill text-white">
                <span style={{marginLeft:"2px", marginRight:"2px"}}>
                  <FontAwesomeIcon className="fa-fw text-white" icon={faEnvelope}></FontAwesomeIcon>
                </span>
                <span className="d-none d-md-inline">contact</span>
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
